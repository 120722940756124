import React from 'react'
import PropTypes from 'prop-types'
import { css } from '@emotion/core'
import { Link } from 'gatsby'
import { Box, Row } from './ui'
import logoImg from '../images/logo.png'

const linkClass = ({ path, routePath }) => (path === routePath ? 'active' : undefined)

const logoTransition = css`
  .bar1 {
    transform: rotate(-45deg) translate(-3px, 6px);
  }

  .bar2 {
    opacity: 0;
  }

  .bar3 {
    transform: rotate(45deg) translate(-3px, -8px);
  }
`

const HamburgerButton = ({ isOpen, onClick }) => {
  return (
    <div
      onClick={onClick}
      css={css`
        & {
          display: inline-block;
          cursor: pointer;
        }

        .bar1,
        .bar2,
        .bar3 {
          width: 24px;
          height: 3px;
          margin: 4px 0;
          transition: 0.4s;
          background-color: rgb(194, 194, 194);
        }

        ${isOpen ? logoTransition : undefined}
      `}
    >
      <div className="bar1"></div>
      <div className="bar2"></div>
      <div className="bar3"></div>
    </div>
  )
}

const MobileNav = ({ routePath }) => {
  let [isOpen, setIsOpen] = React.useState(false)

  return (
    <nav
      className="nav-mobile"
      css={css`
        width: 100%;
        text-align: center;

        font-size: 12px;
        text-transform: uppercase;
        letter-spacing: 1px;
        font-weight: 400;
        font-style: normal;

        a {
          display: block;
          color: rgb(194, 194, 194);

          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          text-decoration: none;
          text-transform: uppercase;
          letter-spacing: 1px;
        }
      `}
    >
      <Row height={45} justifyContent="center" alignItems="center" position="relative">
        <Box position="absolute" left={0} px={3}>
          <HamburgerButton isOpen={isOpen} onClick={() => setIsOpen(value => !value)} />
        </Box>

        <Box
          width={1}
          px={44}
          css={css`
            overflow: hidden;
            white-space: nowrap;

            a {
              color: #fff;
              font-size: 14px;
              font-weight: bold;
            }
          `}
        >
          <a href="/">Pro Pallet</a>
        </Box>
      </Row>

      <ul
        css={css`
          display: ${isOpen ? 'block' : 'none'};

          list-style: none;
          padding: 0;
          margin: 0;

          li {
            padding: 0;
          }

          a {
            border-top: 1px solid rgba(255, 255, 255, 0.05);
            padding: 0.7em 0;
          }
          a.active {
            color: #fff;
          }
        `}
      >
        <li>
          <Link to="/" className={linkClass({ path: 'home', routePath })}>
            Home
          </Link>
        </li>

        <li>
          <Link
            to="/custom-shipping-containers"
            className={linkClass({ path: 'custom-shipping-containers', routePath })}
          >
            Custom Shipping Containers
          </Link>
        </li>

        <li>
          <Link
            to="/prairie-wood-products"
            className={linkClass({ path: 'prairie-wood-products', routePath })}
          >
            Prairie Wood Products of MN
          </Link>
        </li>

        <li>
          <Link to="/contact-us" className={linkClass({ path: 'contact-us', routePath })}>
            Contact
          </Link>
        </li>
      </ul>
    </nav>
  )
}

///////////////////////////////////////////////////////////////////////////////

const DesktopNav = ({ routePath }) => {
  return (
    <Row justifyContent="center" alignItems="center" className="nav-desktop">
      <Row width={188} alignItems="center">
        <img src={logoImg} alt="Pro Pallet" />
      </Row>

      <Box
        mx={3}
        css={{
          a: {
            color: '#ddd',
            textTransform: 'uppercase',
            textDecoration: 'none',
            fontSize: 13,
            fontWeight: 600,
            padding: '0 4px',
          },
          'a:hover': {
            color: '#fff',
          },
          'a.active': {
            color: '#fff',
          },
        }}
      >
        <Link to="/" className={linkClass({ path: 'home', routePath })}>
          Home
        </Link>

        <Link
          to="/custom-shipping-containers"
          className={linkClass({ path: 'custom-shipping-containers', routePath })}
        >
          Custom Shipping Containers
        </Link>

        <Link
          to="/prairie-wood-products"
          className={linkClass({ path: 'prairie-wood-products', routePath })}
        >
          Prairie Wood Products of MN
        </Link>

        <Link to="/contact-us" className={linkClass({ path: 'contact-us', routePath })}>
          Contact
        </Link>
      </Box>
    </Row>
  )
}

///////////////////////////////////////////////////////////////////////////////

const Header = ({ routePath, siteTitle }) => {
  return (
    <header
      css={css`
        display: flex;
        justify-content: center;
        align-items: center;
        background: #000;

        @media only screen and (max-width: 640px) {
          .nav-mobile {
            display: block;
          }
          .nav-desktop {
            display: none;
          }
        }

        @media only screen and (min-width: 640px) {
          height: 130px;

          .nav-mobile {
            display: none;
          }

          .nav-desktop {
            display: flex;
          }
        }
      `}
    >
      <MobileNav routePath={routePath} />
      <DesktopNav routePath={routePath} />
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
