import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import { Box, Column } from './ui'

import Header from './header'
import './layout.css'
// import 'react-image-gallery/styles/css/image-gallery-no-icon.css'
import 'react-image-gallery/styles/css/image-gallery.css'

const Layout = ({ children, routePath }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header routePath={routePath} siteTitle={data.site.siteMetadata.title} />

      <Box
        css={{
          minHeight: 'calc(100vh - 130px)',
          background: '#fff',
        }}
      >
        <main
          css={{
            margin: `0 auto`,
            maxWidth: 1140,
            padding: `0px 1.0875rem 1.45rem`,
            paddingTop: 0,
          }}
        >
          {children}
        </main>

        <footer
          css={{
            fontSize: 12,
            marginTop: 64,
            padding: '16px',
            borderTop: '1px solid #eee',
          }}
        >
          <Column width={1} alignItems="center" color="#666">
            <Box fontWeight="bold">{new Date().getFullYear()} © Pro Pallet</Box>
            <Box>151 12th Ave NW, West Fargo, ND 58078 | 701-281-0431</Box>
          </Column>
        </footer>
      </Box>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
