import styled from '@emotion/styled'
import { css } from '@emotion/core'
import { space, layout, position, typography, color, border, flexbox } from 'styled-system'

const { flex } = flexbox

const Box = styled.div`
  ${space}
  ${layout}
  ${position}
  ${typography}
  ${color}
  ${flex}
  ${flexbox}
  ${border}
`

const Row = styled(Box)(
  {
    display: 'flex',
  },
  flexbox
)

const Column = styled(Box)(
  {
    display: 'flex',
    flexDirection: 'column',
  },
  flexbox
)

export const imageGalleryCss = ({ height } = { height: 360 }) => css`
  .image-gallery-thumbnail {
    max-height: 100px;
    border: none;
    padding: 2px;
    opacity: 0.5;

    &.active {
      border: none;
      opacity: 1;
    }
  }

  .image-gallery-thumbnail-inner {
    height: 64px;
    overflow: hidden;
  }

  .image-gallery-left-nav,
  .image-gallery-right-nav {
    font-size: 2.8em;
  }

  .image-gallery-image {
    max-height: ${height}px;

    img {
      width: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  .image-gallery-fullscreen-button:hover::before,
  .image-gallery-play-button:hover::before,
  .image-gallery-left-nav:hover::before,
  .image-gallery-right-nav:hover::before {
    color: inherit;
  }
`

export { Box, Row, Column }
